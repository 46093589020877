<template>
  <div class="content">
    <div class="contentBox">
      <!-- <img class="leftBgImg" :src="adminLoginPics" alt="" /> -->
      <img
        v-if="DomainName == 'gzjiejia.admin.8bami.com'"
        class="leftBgImg"
        src="../assets/img/bgImg.png"
        alt=""
      />
      <div class="box_log loginbox">
        <div class="main">
          <div class="top">
            <img class="logostyle2" :src="adminLoginlogoUrl" alt="" />
          </div>
          <h3 class="tips_tit">用户登录</h3>
          <!-- 账号登录 -->
          <el-form
            @submit.native.prevent
            v-if="loginType == 'a'"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0"
            class="demo-ruleForm"
          >
            <el-form-item prop="userName" class="rowItem">
              <el-input
                class="loginInput"
                placeholder="登录账号"
                v-model="ruleForm.userName"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="password" class="rowItem">
              <el-input
                class="loginInput"
                placeholder="登录密码"
                type="password"
                v-model="ruleForm.password"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <div v-if="DomainName !== 'gzjiejia.admin.8bami.com'">
              <el-form-item prop="EnterpriseNo" class="rowItem">
                <el-input
                  class="loginInput"
                  placeholder="企业编号"
                  v-model="ruleForm.EnterpriseNo"
                ></el-input>
              </el-form-item>
            </div>

            <el-form-item style="text-align: center">
              <el-button class="subBtn" type="primary" @click="submitForm()"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
          <!-- 验证码登录 -->
          <el-form
            v-else
            :model="ruleForm2"
            ref="ruleForm2"
            label-width="0"
            @submit.native.prevent
            class="demo-ruleForm"
          >
            <el-form-item label="" class="rowItem">
              <el-input
                class="loginInput"
                placeholder="请输入手机号码"
                v-model.trim="ruleForm2.userName"
              ></el-input>
            </el-form-item>
            <el-form-item label="" class="rowItem">
              <div class="rowCode">
                <el-input
                  class="loginInput"
                  placeholder="请输入验证码"
                  type="text"
                  v-model.trim="ruleForm2.code"
                  autocomplete="off"
                ></el-input>
                <button
                  :class="codeStatus ? 'getCodeBtn' : 'activeClass'"
                  @click="isApplyForTrial"
                  :disabled="codeStatus"
                >
                  {{ codeText }}
                </button>
              </div>
            </el-form-item>

            <el-form-item style="text-align: center">
              <el-button
                class="subBtn"
                type="primary"
                @click="oauthAdminByCode()"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import common from "@/assets/js/comm";
// import { parse } from "qs";
export default {
  data() {
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      }
      callback();
    };
    var checkEnterpriseNo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("企业编号不能为空"));
      }
      callback();
    };
    var checkUserName2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      }
      callback();
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      }
      callback();
    };
    return {
      ruleForm: {
        password: "",
        userName: "",
        sign: "",
        EnterpriseNo: "",
      },
      ruleForm2: {
        code: "",
        userName: "",
        prefix: "",
        sign: "",
      },

      rules: {
        password: [{ validator: validatePass, trigger: ["blur", "change"] }],
        userName: [{ validator: checkUserName, trigger: ["blur", "change"] }],
        EnterpriseNo: [
          { validator: checkEnterpriseNo, trigger: ["blur", "change"] },
        ],
      },
      rules2: {
        code: [{ validator: validateCode, trigger: ["blur", "change"] }],
        userName2: [{ validator: checkUserName2, trigger: ["blur", "change"] }],
      },
      systemType: 1,
      systemTypeList: [
        {
          value: 0,
          label: "运维系统",
        },
        {
          value: 1,
          label: "管理系统",
        },
      ],
      loginType: "a", //登录方式, a==账号密码登录  b==验证码登录
      codeText: "获取验证码",
      codeStatus: false, //true == 正在获取验证码 false == 没有在倒计时
      timer: "", //计时器

      UserData: {}, //租户信息
      userType: "", //租户
      adminLoginlogoUrl: "../assets/img/logoimg.png",
      // adminLoginPics: "../assets/img/bgImg.png",
      adminLoginPics: "",
      DomainName: "",
    };
  },
  //页面卸载
  Destroyed() {
    clearInterval(this.timer);
    this.timer = "";
  },
  watch: {},
  created() {
    // let DomainName = tools.getDomainName();
    // this.DomainName = DomainName;
    // if (DomainName == "gzjiejia.admin.8bami.com") {
    //   this.ruleForm.EnterpriseNo = "j08";
    // }
    // zt.dg.admin.8bami.com
    // if (DomainName == "admin.ywhhwl.com") {
    //   document.title = "中坦国际TMS";
    // } else {
    //   document.title = "八米跨境TMS";
    // }

    let uk = window.localStorage.getItem("UserKey") || "";
    let ifReLogin = window.localStorage.getItem("ifReLogin") || false; //是否是重新登陆
    if (uk && !ifReLogin) {
      this.$router.push("/baseIndex");
    }
    let falg = common.loginOpen || false; //true == 申请试用,false == 普通登录
  },
  mounted() {
    if (window.localStorage.getItem("userList")) {
      let userList = JSON.parse(window.localStorage.getItem("userList"));

      this.ruleForm.userName = userList[0].userName;
      this.ruleForm.password = decodeURIComponent(userList[0].password);
    }
    // let EnterpriseNo = 'huayuan1001'  //集运
    // let EnterpriseNo = 'bamibuytest'     //代购
    this.ruleForm.EnterpriseNo =
      window.localStorage.getItem("EnterpriseNo") || "";

    let DomainName = tools.getDomainName();
    this.DomainName = DomainName;
    if (DomainName == "gzjiejia.admin.8bami.com") {
      this.ruleForm.EnterpriseNo = "j08";
    }

    if (!this.ruleForm.EnterpriseNo) {
      this.open();
    } else {
      this.getAdminLoginPic();
    }
  },
  methods: {
    open() {
      this.$prompt("请输入企业编号", "提示", {
        confirmButtonText: "确定",
        inputPattern: /^[a-zA-Z0-9]{3,}/,
        inputErrorMessage: "企业编号格式不正确",
        showCancelButton: false,
      })
        .then(({ value }) => {
          this.ruleForm.EnterpriseNo = value;
          window.localStorage.setItem("EnterpriseNo", value);
          this.getAdminLoginPic();
        })
        .catch(() => {});
    },
    // 切换
    open2() {
      this.$prompt("请输入企业编号", "提示", {
        confirmButtonText: "确定",
        inputPattern: /^[a-zA-Z0-9]{3,}/,
        inputErrorMessage: "企业编号格式不正确",
        inputPlaceholder: this.ruleForm.EnterpriseNo || "请输入",
      })
        .then(({ value }) => {
          this.ruleForm.EnterpriseNo = value;
          window.localStorage.setItem("EnterpriseNo", value);
          this.getAdminLoginPic();
        })
        .catch(() => {});
    },
    getAdminLoginPic() {
      // 左上角logo
      this.$API.getSystemVI().then((res) => {
        let datas = res.data.result || "";
        document.title = datas.tenantSystemName;
        // 租户登陆页logo
        if (datas && datas.tenantAdminLoginLogoUrl) {
          this.adminLoginlogoUrl = datas.tenantAdminLoginLogoUrl || "";
        }
      });

      // 获取租户登陆页信息
      // Api.getAdminLoginPic().then((res) => {
      //   let datas = res.data.result || "";
      //   if (datas && datas.tenantSystemName) {
      //     document.title = datas.tenantSystemName; //网页标题
      //   }
      //   if (datas && datas.adminLoginlogoUrl) {
      //     this.adminLoginlogoUrl = datas.adminLoginlogoUrl; //网页标题
      //   }

      // });
    },
    // 获取租户应用信息
    getTenantApp() {
      var param = {
        appStatus: "admin:app_status",
        domainName: "b9.admin.xhyitsoft.com",
      };
      Api.getTenantApp(param).then((res) => {
        if (res.data.status == "success") {
          this.UserData = res.data.result || {};
        }
      });
    },
    // 校验手机号是否已申请
    isApplyForTrial() {
      var param_a = {
        phone: this.ruleForm2.userName,
        tenantId: this.UserData.tenantId || "1",
      };

      let sign = tools.getSign(param_a) || "1";

      param_a.sign = sign;

      Api.isApplyForTrial(param_a).then((res) => {
        let applyForTrial = res.data.result.applyForTrial || false;
        let applyForTrialUrl = res.data.result.applyForTrialUrl;
        if (!applyForTrial) {
          window.location.href = applyForTrialUrl;
          this.$message.warning(res.data.message || "该手机号未申请试用");
        } else {
          this.numberSplit();
        }
      });
    },
    //点击获取验证码按钮
    // getCode() {
    //   if (this.ruleForm2.userName.length < 10) {
    //     this.$message.warning("手机号不正确");
    //     return;
    //   }
    //   this.isApplyForTrial();
    // },

    sendCode() {
      // this.numberSplit();
      // let that = this;
      // that.codeStatus = true;
      // that.codeText = 60;
      // clearInterval(that.timer);
      // that.timer = setInterval(() => {
      //   that.codeText--;
      //   if (that.codeText <= 0) {
      //     clearInterval(that.timer);
      //     that.timer = "";
      //     that.codeStatus = false;
      //     that.codeText = "获取验证码";
      //   }
      // }, 1000);
    },
    // 发送短信
    numberSplit() {
      let that = this;
      var para = {
        phone: this.ruleForm2.userName,
        prefix: common.prefix || "pc_prefix",
        smsStatus: "phoneLoginCode", //短信类型： 发送手机登陆验证码
      };
      let sign = tools.getSign(para);
      para.sign = sign;
      Api.numberSplit(para).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "发送成功");
          that.codeStatus = true;
          that.codeText = 60;
          clearInterval(that.timer);
          that.timer = setInterval(() => {
            that.codeText--;
            if (that.codeText <= 0) {
              clearInterval(that.timer);
              that.timer = "";
              that.codeStatus = false;
              that.codeText = "获取验证码";
            }
          }, 1000);
        }
      });
    },

    //验证码登录
    oauthAdminByCode() {
      let that = this;
      let param = {
        code: this.ruleForm2.code, //验证码
        userName: this.ruleForm2.userName,
        prefix: common.prefix || "pc_prefix", //短信前缀
      };
      let sg = tools.getSign(param);
      param.sign = sg;
      Api.oauthAdminByCode(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "登录成功");
          window.localStorage.setItem("UserKey", res.data.result.userKey);
          window.localStorage.setItem("ifReLogin", false); //用来判断是否是登录失效后跳转到登录页
          setTimeout(() => {
            that.$router.push({
              path: "/baseIndex",
            });
          }, 1000);
        }
      });
    },

    // submitForm() {
    //   let that = this;
    //   let signParam = {
    //     password: that.ruleForm.password,
    //     userName: that.ruleForm.userName,
    //   };
    //   let sign = tools.getSign(signParam); //调用getSign方法获取签名,该方法在tool.js里面
    //   let param = {
    //     password: that.ruleForm.password,
    //     userName: that.ruleForm.userName,
    //     sign: sign,
    //   };
    //   Api.oauthAdmin(param).then((res) => {
    //     this.$message.success(res.data.message || "登录成功");
    //     window.sessionStorage.setItem("UserKey", res.data.result.userKey);
    //     setTimeout(() => {
    //       that.$router.push({
    //         path: "/baseIndex",
    //       });
    //     }, 1000);
    //   });
    // },

    submitForm() {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          window.localStorage.setItem(
            "EnterpriseNo",
            that.ruleForm.EnterpriseNo
          );
          //
          let signParam = {
            password: that.ruleForm.password,
            userName: that.ruleForm.userName,
          };
          let sign = tools.getSign(signParam); //调用getSign方法获取签名,该方法在tool.js里面
          let param = {
            password: that.ruleForm.password,
            userName: that.ruleForm.userName,
            sign: sign,
          };
          let uesrarray = {
            password: encodeURIComponent(that.ruleForm.password),
            userName: that.ruleForm.userName,
          };
          window.localStorage.setItem("UserKey", "");
          Api.oauthAdmin(param)
            .then((res) => {
              this.$message.success(res.data.message || "登录成功");
              window.localStorage.setItem("UserKey", res.data.result.userKey);
              window.localStorage.setItem("ifReLogin", false); //用来判断是否是登录失效后跳转到登录页
              //账号缓存
              let userList = [];
              if (JSON.parse(window.localStorage.getItem("userList"))) {
                userList = JSON.parse(window.localStorage.getItem("userList"));

                userList[0].userName = uesrarray.userName;
                userList[0].password = uesrarray.password || "";
                window.localStorage.setItem(
                  "userList",
                  JSON.stringify(userList)
                );
              } else {
                //第一次缓存
                console.log("第一次缓存");
                userList.push(uesrarray);
                window.localStorage.setItem(
                  "userList",
                  JSON.stringify(userList)
                );
              }
              // 判断是集运还是代购
              Api.getTenantType().then((res) => {
                window.localStorage.setItem(
                  "tenantType",
                  res.data.result.tenantType || ""
                );
              });

              setTimeout(() => {
                that.$router.push({
                  path: "/baseIndex",
                });
              }, 1000);
            })
            .catch((error) => {
              window.localStorage.setItem("UserKey", "");
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang='scss'>
$myColor: #ef7c1b;

.content {
  // background: url("../assets/img/bgc3.jpg") no-repeat;
  //background: url("../assets/img/bgc1.jpg") no-repeat;
  background: url("../assets/img/bgc2.png") no-repeat;
  background-size: 100% 100%;
  background-color: #f2f2f2;
  height: 100vh;
}

.contentBox {
  width: 90%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // justify-content: flex-end;
  margin: 0 auto;
  box-sizing: border-box;

  .loginbox {
    .el-input__inner {
      // border-bottom: 1px solid #d7d7d7;

      height: 40px;
      line-height: 40px;
      min-height: 40px;
    }
  }

  .leftBgImg {
    flex: 1;
    min-width: 400px;
    max-width: 600px;
    margin-right: 100px;
    // height: 540px;
  }

  .box_log {
    // width: 25%;
    min-width: 400px;
    max-width: 600px;
    // margin: 0 auto;
    width: 400px;

    margin-right: 80px;

    .main {
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 0 10px #d7d7d7;
      // height: 360px;
      background-color: #fff;
      // box-shadow: 3px 3px 6px #f2f2f2;
      box-sizing: border-box;
      padding: 30px 50px 10px 50px;

      .top {
        width: 100%;

        // background-color: #f00;
        // position: relative;
        // display: flex;
        // align-items: center;
        position: relative;
        text-align: center;

        .logoIcon {
          width: 60px;
          height: 60px;
          margin-right: 56px;
          position: absolute;
          left: 0;
          top: 20px;
        }

        .logostyle {
          width: 70%;
        }

        .logostyle2 {
          width: 70%;
        }

        .title {
          // width: 100%;
          // flex: 1;
          text-align: center;
        }
      }

      .subBtn {
        margin: 20px auto;
        background-color: #ef7c1b;
        width: 100%;
      }

      .el-button {
        border: 0;
        padding: 15px 0;
      }
    }
  }

  ::v-deep {
    .el-input__inner {
      height: 40px;
      line-height: 40px;
      min-height: 40px;
    }
  }

  .rowItem {
    // border: 1px solid #d7d7d7;
    margin-bottom: 20px;
  }

  .rowCode {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .loginInput {
      flex: 1;
    }

    .activeClass {
      margin: 0;
      padding: 0 8px;
      margin-left: 10px;
      height: 38px;
      line-height: 38px;
      border: none;
      color: #fff;
      background-color: $myColor;
      border-radius: 4px;
      width: 86px;
    }

    .getCodeBtn {
      margin: 0;
      padding: 0 8px;
      margin-left: 10px;
      height: 38px;
      line-height: 38px;
      border-radius: 4px;
      width: 86px;
      border: 1px solid #d7d7d7;
      color: #333;
    }
  }
}
</style>